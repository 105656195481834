export const imgLourdes = {
    src: 'images/lourdes.png',
    alt: '',
    text: '',
    linkTo: '/gruta'
};

export const cura = {
    title: 'Testemunho de Cura',
    linkTo: '/cura'
}

export const historias = {
    title: 'Histórias sobre a Brígida',
    linkTo: '/testemunho'
}
